import {createApi} from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import store from '../store';
import _ from 'lodash';

const axiosBaseQuery =
  ({baseUrl} = {baseUrl: ''}) =>
    async ({url, method = 'GET', data, params, headers}) => {
      const {session} = store.getState();
      const _headers = {
        'x-inscope-org': session.currentOrg.orgId,
        ...headers,
      }
      if (!_.isNil(session.accessToken)) {
        _headers.Authorization = `Bearer ${session.accessToken}`;
      }
      try {
        return await axios({
          url: `${baseUrl}${url}`,
          method,
          data,
          params,
          headers: _headers,
        });
      } catch (axiosError) {
        const err = axiosError
        return {
          error: {
            code: err.code,
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        }
      }
    }

export const disclosureReviewApiSlice = createApi({
  reducerPath: 'disclosureReviewApi',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_DISCLOSURE_ASSISTANT_API_URL,
  }),
  endpoints: builder => ({
    getAllAvailableDisclosures: builder.query({
      query: ({documentId}) => ({
        url: `/disclosures/${documentId}`,
        method: 'GET',
      }),
    }),
    generateDisclosure: builder.mutation({
      query: ({documentId, data}) => ({
        url: `/disclosures/${documentId}/generate`,
        method: 'POST',
        data,
      }),
    }),
    dismissTag: builder.mutation({
      query: ({documentId, tagId}) => ({
        url: `/disclosures/${documentId}/dismiss/${tagId}`,
        method: 'POST',
        data: {},
      }),
    }),
    undismissTag: builder.mutation({
      query: ({documentId, tagId}) => ({
        url: `/disclosures/${documentId}/undismiss/${tagId}`,
        method: 'POST',
        data: {},
      }),
    }),
  }),
});

export const {
  endpoints,
  useGetAllAvailableDisclosuresQuery,
  useGenerateDisclosureMutation,
  useDismissTagMutation,
  useUndismissTagMutation,
} = disclosureReviewApiSlice;
