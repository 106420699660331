import React, {useState, useEffect} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Tooltip, Button, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import {Copy} from 'lucide-react';
import {BLACK_100} from '../../theme';

const CopyButton = ({textToCopy, buttonStyle, buttonIconStyle, buttonLabelStyle, options}) => {
  const [copied, setCopied] = useState(false);

  const onCopy = (text, result) => {
    setCopied(result);
  }

  useEffect(() => {
    const t = copied ? setTimeout(() => setCopied(false), 1000) : null;
    return () => clearTimeout(t);
  }, [copied, setCopied]);

  const copyButtonStyles = {
    borderRadius: '8px',
    backgroundColor: '#fff',
    border: '1px solid #DDE0DE',
    boxShadow: 'none',
    color: BLACK_100,
    height: '32px',
    width: '88px',
  };

  return (
    <CopyToClipboard onCopy={onCopy} text={textToCopy} options={options}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        open={copied}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title='Copied!'>
        <Button
          variant='text'
          startIcon={<Copy height={12} width={12} {...buttonIconStyle} />}
          sx={{...copyButtonStyles, ...buttonStyle}}>
          <Typography style={{ml: 1, ...buttonLabelStyle}} variant='body2'>Copy</Typography>
        </Button>
      </Tooltip>
    </CopyToClipboard>
  );
}

CopyButton.propTypes = {
  textToCopy: PropTypes.string.isRequired,
  buttonStyle: PropTypes.object,
  buttonIconStyle: PropTypes.object,
  buttonLabelStyle: PropTypes.object,
  options: PropTypes.shape({
    format: PropTypes.oneOf(['text/plain', 'text/html']).isRequired,
  }),
};

export default CopyButton;
