import {createSlice} from '@reduxjs/toolkit';
import _ from 'lodash';
import {v4 as uuidv4} from 'uuid';

const INITIAL_STATE = {
  documentId: null,
  lastRunTimestamp: null,
  items: {},
  generatedDisclosures: {},
  selectedItem: null,
  generatedDisclosure: null,
  popoverOpen: false,
}

export const disclosureReviewSlice = createSlice({
  name: 'disclosureReview',
  initialState: INITIAL_STATE,
  reducers: {
    setItems: (state, action) => {
      state.documentId = action.payload.documentId;
      state.lastRunTimestamp = action.payload.lastRunTimestamp;
      let _items = {};
      if (_.has(action, 'payload.policyGroup') && !_.isNil(action.payload.policyGroup) && !_.isEmpty(action.payload.policyGroup)) {
        const _id = uuidv4();
        _items[_id] = {..._.get(action, 'payload.policyGroup', {}), id: _id, type: 'policyGroup'};
        _items[_id].disclosures = _.map(
          _.orderBy(
            _items[_id].disclosures,
            _disclosure => {
              const index = action.payload.documentIds.indexOf(_.first(_.split(_.get(_disclosure, 'text_id', null), ', ')));
              return index === -1 ? action.payload.documentIds.length : index;
            },
          ),
          disclosure => ({...disclosure, isComplete: false, isDismissed: false, viewed: false}),
        );
      }
      _.forEach(_.get(action, 'payload.footnoteGroups', []), (footnoteGroup) => {
        if (_.isEmpty(footnoteGroup) || _.isNil(footnoteGroup)) return;
        const _id = uuidv4();
        _items[_id] = {...footnoteGroup, id: _id, type: 'footnoteGroup'};
        _items[_id].disclosures = _.map(
          _.orderBy(
            _items[_id].disclosures,
            _disclosure => {
              const index = action.payload.documentIds.indexOf(_.first(_.split(_.get(_disclosure, 'text_id', null), ', ')));
              return index === -1 ? action.payload.documentIds.length : index;
            },
          ),
          disclosure => ({...disclosure, isComplete: false, isDismissed: false, viewed: false}),
        );
      });
      const _sortedItems = _.orderBy(
        _.values(_items),
        _item => {
          const index = action.payload.documentIds.indexOf(_.first(_.split(_.get(_item, 'disclosures[0].text_id', null), ', ')));
          return index === -1 ? action.payload.documentIds.length : index;
        },
      )
      _items = _.keyBy(_sortedItems, 'id');
      state.items = _items;
      state.generatedDisclosures = {};
      if (!_.has(_sortedItems, {id: _.get(state, 'selectedItem.id')})) {
        state.selectedItem = null;
      }
    },
    markDisclosureViewed: (state, action) => {
      const _items = state.items;
      const _disclosureIdx = _.findIndex(_items[action.payload.itemId].disclosures, {id: action.payload.disclosureId});
      _items[action.payload.itemId].disclosures[_disclosureIdx].viewed = true;
      state.items = _items;
      state.selectedItem = _items[action.payload.itemId];
    },
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload.selectedItem;
      if (!_.isNil(action.payload.selectedItem)) {
        state.popoverOpen = true;
      }
    },
    setPopoverOpen: (state, action) => {
      state.popoverOpen = action.payload.popoverOpen;
    },
    markComplete: (state, action) => {
      const _items = state.items;
      const _item = _items[action.payload.itemId];
      const _disclosure = _.find(_item.disclosures, disclosure => disclosure.id === action.payload.disclosureId);
      _disclosure.isComplete = action.payload.isComplete;
      state.items = _items;
      state.selectedItem = _item;
      if (_.every(_item.disclosures, disclosure => (disclosure.isComplete || disclosure.isDismissed))) {
        state.selectedItem = null;
        state.popoverOpen = false;
      }
    },
    dismiss: (state, action) => {
      const _items = state.items;
      const _item = _items[action.payload.itemId];
      const _disclosure = _.find(_item.disclosures, disclosure => disclosure.id === action.payload.disclosureId);
      _disclosure.isDismissed = action.payload.isDismissed;
      state.items = _items;
      state.selectedItem = _item;
      if (_.every(_item.disclosures, disclosure => (disclosure.isComplete || disclosure.isDismissed))) {
        state.selectedItem = null;
        state.popoverOpen = false;
      }
    },
    setGeneratedDisclosure: (state, action) => {
      state.generatedDisclosure = action.payload.generatedDisclosure;
      state.generatedDisclosures[action.payload.disclosureId] = action.payload.generatedDisclosure;
    },
    reset: () => INITIAL_STATE,
  },
});

// Action creators are generated for each case reducer function
export const {setItems, reset, setSelectedItem, setPopoverOpen, markComplete, dismiss, setGeneratedDisclosure, markDisclosureViewed, addGeneratedDisclosure} = disclosureReviewSlice.actions;

export default disclosureReviewSlice.reducer;
