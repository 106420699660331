import {combineReducers, configureStore} from '@reduxjs/toolkit';
import sessionReducer from './data/session';
import periodsReducer from './data/periods';
import reportsReducer from './data/reports';
import disclosureReviewReducer from './data/disclosureReview';
import documentsReducer from './data/documents';
import copilotReducer from './data/copilot';
import {apiSlice} from './data/api';
import {copilotApiSlice} from './data/copilotApi';
import {excelApiSlice} from './data/excelApi';
import {disclosuresApiSlice} from './data/disclosuresApi';
import {documentWorkspaceApiSlice} from './data/documentWorkspaceApi';
import {disclosureReviewApiSlice} from './data/disclosureReviewApi';
import storage from 'redux-persist/lib/storage'
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['copilot', 'disclosureReview'],
}

export const rootReducers = combineReducers({
  copilot: copilotReducer,
  periods: periodsReducer,
  reports: reportsReducer,
  disclosureReview: disclosureReviewReducer,
  documents: documentsReducer,
  session: sessionReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  [copilotApiSlice.reducerPath]: copilotApiSlice.reducer,
  [excelApiSlice.reducerPath]: excelApiSlice.reducer,
  [disclosureReviewApiSlice.reducerPath]: disclosureReviewApiSlice.reducer,
  [disclosuresApiSlice.reducerPath]: disclosuresApiSlice.reducer,
  [documentWorkspaceApiSlice.reducerPath]: documentWorkspaceApiSlice.reducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducers)

export default configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(apiSlice.middleware, copilotApiSlice.middleware, excelApiSlice.middleware, disclosureReviewApiSlice.middleware, disclosuresApiSlice.middleware, documentWorkspaceApiSlice.middleware),
});
