import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {withLDConsumer} from 'launchdarkly-react-client-sdk';
import LoadingButton from '@mui/lab/LoadingButton';
import DownloadIcon from '@mui/icons-material/Download';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import {triggerDownload} from '../util';
import * as api from '../util/api';
import {Checkbox, FormControl, FormControlLabel, FormGroup, IconButton, Modal, Stack} from '@mui/material';
import {APP_HEADER_HEIGHT, BLACK_100, GREY_70, WHITE_100} from '../App';
import CloseIcon from '@mui/icons-material/Close';
import {REPORT_TYPES} from '../constants';
import Button from '@mui/material/Button';

const buildDefaultSelectedReports = reports => {
  const _selectedReports = {};
  _.forEach(reports, _report => {
    _selectedReports[_report.record.reportType] = true;
  });
  return _selectedReports;
}

const DownloadReportComponent = ({flags, reports, visible}) => {
  const {currentOrg} = useSelector((state) => state.session);
  const {selectedPeriod: period, selectedComparePeriod: comparePeriod} = useSelector((state) => state.periods);
  const [downloadingReports, setDownloadingReports] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedReports, setSelectedReports] = useState(buildDefaultSelectedReports(reports));

  useEffect(() => {
    setSelectedReports(buildDefaultSelectedReports(reports));
  }, [reports]);

  const downloadReports = async () => {
    setDownloadingReports(true);
    try {
      const _filename = `${_.has(currentOrg, 'org_metadata.companyLegalName') ? currentOrg.org_metadata.companyLegalName : currentOrg.orgName} - ${period}.xlsx`;
      let _payload;
      const _params = {
        period,
      }
      if (!flags.userCanSelectWhichReportToDownloadEng537) {
        _payload = {
          reports: _.map(reports, _report => _report.record.fileId),
        }
        const _compareReports = _.map(reports, _report => !_.isNil(_report.compareRecord) ? _report.compareRecord.fileId : null);
        if (!_.isEmpty(_compareReports)) {
          _payload.compareReports = _compareReports;
          _params.comparePeriod = comparePeriod;
        }
      } else {
        const _reportIds = [];
        _.forEach(reports, _report => {
          if (selectedReports[_report.record.reportType]) {
            _reportIds.push(_report.record.fileId);
          }
        });
        _payload = {
          reports: _reportIds,
        }
        const _compareReportIds = [];
        _.forEach(reports, _report => {
          if (!_.isNil(_report.compareRecord) && selectedReports[_report.record.reportType]) {
            _compareReportIds.push(_report.compareRecord.fileId);
          }
        });
        if (!_.isEmpty(_compareReportIds)) {
          _payload.compareReports = _compareReportIds;
          _params.comparePeriod = comparePeriod;
        }
      }
      await triggerDownload(() => api.downloadReports(_payload, _params), _filename);
      setSelectedReports(buildDefaultSelectedReports(reports));
      onClose();
    } catch (_error) {
      console.error(_error);
    } finally {
      setDownloadingReports(false);
    }
  }

  const handleReportSelection = (reportType) => (event) => {
    setSelectedReports({...selectedReports, [reportType]: event.target.checked});
  }

  const onClose = () => {
    setModalOpen(false);
  }

  if (!visible) {
    return null;
  }

  if (!flags.userCanSelectWhichReportToDownloadEng537) {
    return (
      <LoadingButton onClick={() => downloadReports()} startIcon={<DownloadIcon/>} variant="contained"
                     color={'darkGreen'} loading={downloadingReports} sx={{fontWeight: '700'}}>
        Download
      </LoadingButton>
    )
  } else {
    return (
      <React.Fragment>
        <Modal
          open={modalOpen}
          onClose={onClose}>
          <Box position={'absolute'} sx={{left: '50%', top: `calc(${APP_HEADER_HEIGHT} + 1.75rem)`, transform: 'translate(-50%, 0)'}}>
            <Box width={'33.1875rem'}>
              <Stack direction={'column'} height={'100%'}>
                <Stack direction={'row'} justifyContent={'space-between'} minHeight={'4.25rem'} maxHeight={'4.25rem'} paddingX={'1.75rem'} alignItems={'center'} bgcolor={WHITE_100} borderRadius={'0.5rem 0.5rem 0 0'}>
                  <Typography fontSize={'1.2rem'} fontStyle={'normal'} fontWeight={'700'} lineHeight={'130%'} letterSpacing={'0.025rem'} color={BLACK_100}>Select the reports you would like to download </Typography>
                  <IconButton sx={{padding: 0}} onClick={onClose}>
                    <CloseIcon/>
                  </IconButton>
                </Stack>
                <Box width={'100%'} sx={{backgroundColor: GREY_70, borderRadius: '0 0 0.5rem 0.5rem'}} paddingX={'2rem'} paddingY={'1.25rem'}>
                  <FormControl component="fieldset" fullWidth>
                    <FormGroup aria-label="position" sx={{flex: 1, display: 'flex', marginBottom: '2.5rem'}}>
                      <FormControlLabel
                        key={'BALANCE_SHEET'}
                        checked={selectedReports.BALANCE_SHEET}
                        onChange={handleReportSelection('BALANCE_SHEET')}
                        value={'BALANCE_SHEET'}
                        control={<Checkbox />}
                        label={REPORT_TYPES.BALANCE_SHEET.title}
                        labelPlacement="start"
                        sx={{flex: 1, justifyContent: 'space-between', marginLeft: 0}}
                      />
                      <FormControlLabel
                        key={'INCOME_STATEMENT_GAAP'}
                        checked={selectedReports.INCOME_STATEMENT_GAAP}
                        onChange={handleReportSelection('INCOME_STATEMENT_GAAP')}
                        value={'INCOME_STATEMENT_GAAP'}
                        control={<Checkbox />}
                        label={REPORT_TYPES.INCOME_STATEMENT_GAAP.title}
                        labelPlacement="start"
                        sx={{flex: 1, justifyContent: 'space-between', marginLeft: 0}}
                      />
                      <FormControlLabel
                        key={'INCOME_STATEMENT_NON_GAAP'}
                        checked={selectedReports.INCOME_STATEMENT_NON_GAAP}
                        onChange={handleReportSelection('INCOME_STATEMENT_NON_GAAP')}
                        value={'INCOME_STATEMENT_NON_GAAP'}
                        control={<Checkbox />}
                        label={REPORT_TYPES.INCOME_STATEMENT_NON_GAAP.title}
                        labelPlacement="start"
                        sx={{flex: 1, justifyContent: 'space-between', marginLeft: 0}}
                      />
                      <FormControlLabel
                        key={'INDIRECT_CASHFLOW'}
                        checked={selectedReports.INDIRECT_CASHFLOW}
                        onChange={handleReportSelection('INDIRECT_CASHFLOW')}
                        value={'INDIRECT_CASHFLOW'}
                        control={<Checkbox />}
                        label={REPORT_TYPES.INDIRECT_CASHFLOW.title}
                        labelPlacement="start"
                        sx={{flex: 1, justifyContent: 'space-between', marginLeft: 0}}
                      />
                      <FormControlLabel
                        key={'DIRECT_CASHFLOW'}
                        checked={selectedReports.DIRECT_CASHFLOW}
                        onChange={handleReportSelection('DIRECT_CASHFLOW')}
                        value={'DIRECT_CASHFLOW'}
                        control={<Checkbox />}
                        label={REPORT_TYPES.DIRECT_CASHFLOW.title}
                        labelPlacement="start"
                        sx={{flex: 1, justifyContent: 'space-between', marginLeft: 0}}
                      />
                    </FormGroup>
                    <LoadingButton onClick={() => downloadReports()}
                                   startIcon={<DownloadIcon/>}
                                   variant="contained"
                                   color={'darkGreen'}
                                   loading={downloadingReports}
                                   disabled={_.every(_.values(selectedReports), _i => !_i)}
                                   sx={{fontWeight: '700'}}>
                      Download
                    </LoadingButton>
                  </FormControl>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Modal>
        <Button onClick={() => setModalOpen(true)} startIcon={<DownloadIcon/>} variant="contained"
                       color={'darkGreen'} sx={{fontWeight: '700'}}>
          Download
        </Button>
      </React.Fragment>
    )
  }
}

DownloadReportComponent.propTypes = {
  flags: PropTypes.shape({
    userCanSelectWhichReportToDownloadEng537: PropTypes.bool.isRequired,
  }),
  visible: PropTypes.bool.isRequired,
  reports: PropTypes.array,
}

export default withLDConsumer()(DownloadReportComponent);
